import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import api from "../../api";

function SellerBox(props) {
  const [countOrder, setCountOrder] = useState(0);
  const [countSale, setCountSale] = useState(0);
  const [sumSale, setsumSale] = useState(0);

  const fecthCountOrder = () => {
    api.get(`Dashboard/getOrderCount/` + sessionStorage.getItem("SellerId")).then((res) => {
      const countOrder = res.data.result.order[0].count;
      console.log(countOrder);
      setCountOrder(countOrder);
    });
  };
  const fecthCountSale = () => {
    api.get(`Dashboard/getPaymentCount/` + sessionStorage.getItem("SellerId")).then((res) => {
        const countSale = res.data.result.paiment[0].count;
        console.log(countSale);
        setCountSale(countSale);
      });
  };

  const fecthSumSale = () => {
    api.get(`Dashboard/getPaymentSum/` + sessionStorage.getItem("SellerId")).then((res) => {
        const sumSale = res.data.result.paiment[0].sum;
        console.log(sumSale);
        setsumSale(sumSale);
      });
  };

  useEffect(() => {
    fecthCountOrder();
    fecthCountSale();
    fecthSumSale();
  }, []);

  return (
    <Fragment>
      <Container>
        <Row>
          <Col>
            <Card>
              <Card.Body className="orderBodyCard1">
                <div className="orderCard">
                  <p className="font-weight-bold ">
                    <i class="fas fa-shopping-cart text-danger orderIconx "></i>{" "}
                    Nombre de commande
                  </p>
                  <h5>{countOrder}</h5>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body className="orderBodyCard2">
                <div className="orderCard">
                  <p className="font-weight-bold ">
                    <i class="fas fa-wallet text-danger orderIconx "></i> Vente
                    en nombre
                  </p>
                  <h5>{countSale}</h5>
                </div>
              </Card.Body>
            </Card>
          </Col>{" "}
          <Col>
            <Card>
              <Card.Body className="orderBodyCard3">
                {" "}
                <div className="orderCard">
                  <p className="font-weight-bold ">
                    <i class="fas  fa-funnel-dollar text-danger orderIconx "></i>{" "}
                    Vente en volume
                  </p>
                  <h5>{sumSale} FCFA</h5>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default SellerBox;
