import React, { Fragment, useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import api from "../../api";
import { Badge, Button, Image, ProgressBar } from "react-bootstrap";

import { Toast } from "primereact/toast";

import "./style.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import parse from "html-react-parser";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { addItem } from "../../store";

function DetailProduct() {
  const toast = useRef(null);
  const [key, setKey] = useState("home");
  const [prod, SetProd] = useState({
    prodId: "",
    prodName: "",
    prodPu: "",
    prodImage: "",
    prodBody: "",
    prodPu: 0,
    prodFees: 0,
    prodQte: 0,
    prodCondi: "",
  });
  const [favory, setFavory] = useState(false);

  const fecthFavory = () => {
    const data = {
      product_id: myLink(),
      seller_id: sessionStorage.getItem("SellerId"),
    };
    api.post(`Wishlists/sellerWishlists`, data).then((res) => {
      if (res.data.result.feedback === "success") {
        setFavory(true);
      } else {
        // this.ErrorSwal();
      }
    });
  };

  const myLink = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("IdProd");
  };

  const fetchProduct = () => {
    api.get(`Products/getProduct/` + myLink()).then((res) => {
      SetProd({
        prodId: res.data.result.product.id,
        prodName: res.data.result.product.prod_name,
        prodImage: res.data.result.product.prod_image,
        prodBody: res.data.result.product.prod_body,
        prodPu: res.data.result.product.prod_pu,
        prodFees: 0,
        prodQte: res.data.result.product.stocks[0].qte,
        prodCondi: res.data.result.product.conditions,
      });
    });
  };

  //
  const dispatch = useDispatch();
  const handleAddToCart = () => {
    toast.current.show({
      severity: "success",
      summary: "Mon Panier",
      detail: "Votre article ajouté à votre panier !",
    });
    const newItem = {
      id: prod.prodId,
      name: prod.prodName,
      image: prod.prodImage,
      quantity: 1,
      price: prod.prodPu,
      fees: prod.prodFees,
    };
    dispatch(addItem(newItem));
  };

  const handleAddWishlist = (id) => {
    //
    const data = {
      product_id: id,
      seller_id: sessionStorage.getItem("SellerId"),
    };
    api.post(`Wishlists/add`, data).then((res) => {
      if (res.data.status === "OK") {
        toast.current.show({
          severity: "success",
          summary: "Mes Favoris",
          detail: "Article ajouté à votre favorie !",
        });
        setFavory(true);
      } else {
        // this.ErrorSwal();
      }
    });
  };
  const favoryBody = () => {
    return (
      <button
        type="button"
        class="btn btn-light"
        title=" Articles ajouté à mes favories"
      >
        <i class="fas fa-heart text-danger"></i>
      </button>
    );
  };
  //

  useEffect(() => {
    fetchProduct();
    fecthFavory();
  }, []);

  return (
    <Fragment>
      <section className="py-1">
        <Container>
          <Row>
            <Col>
              <Link to={"/search-product"} className="btn btn-default">
                <i class="fas fa-angle-left"></i> continuer vos achats
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-1">
        <Container>
          <Row>
            <Col md={9} sm={12}>
              <Card className="d-sm-none">
                <Card.Body>
                  <Toast ref={toast} />
                  <div className="mb-3">
                    <Image
                      className="img-fluid detailImg mr-2"
                      // style={{ width: "305px" }}
                      src={"http://medias.e-triangle.com/" + prod.prodImage}
                    />
                  </div>
                  <div className="mb-3">
                    <span className="prodPrice">{prod.prodPu} FCFA</span>
                    <div className="mb-3">
                      <span className="text-danger">
                        {prod.prodQte} articles restants
                      </span>
                      <ProgressBar
                        variant="success"
                        now={prod.prodQte}
                        size={0}
                      />
                    </div>
                    <p className="font-weight-boldx  mb-3">{prod.prodName}</p>
                    <div>
                      <p className="textConditions">
                        {/* + livraison à partir de 300 FCFA (livraison gratuite si
                        supérieur à 5,000 FCFA) vers Koumassi Divo - Remblais -
                        SIR */}
                        {prod.prodCondi}
                      </p>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div class="d-grid gap-2 d-md-block">
                      <button
                        class="btn btn-danger btn-block"
                        type="button"
                        onClick={handleAddToCart}
                      >
                        Ajouter au panier
                      </button>

                      {favory ? (
                        favoryBody()
                      ) : (
                        <button
                          onClick={() => handleAddWishlist(prod.prodId)}
                          class="btn btn-outline-danger btn-block"
                          type="button"
                        >
                          Ajouter à mes favoris
                        </button>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="d-none d-sm-block">
                <Card.Body>
                  <Toast ref={toast} />
                  <div className="d-flex justify-content-beween">
                    <div className="mb-3">
                      <Image
                        className="img-fluidX detailImg mr-2"
                        style={{ width: "305px" }}
                        src={"http://medias.e-triangle.com/" + prod.prodImage}
                      />
                    </div>

                    <div className=" mb-3">
                      <p className="font-weight-boldx  mb-3">{prod.prodName}</p>
                      <span className="prodPrice">{prod.prodPu} FCFA</span>
                      <div className="mb-3">
                        <i className="text-danger">
                          {prod.prodQte} articles restants
                        </i>
                        <ProgressBar
                          variant="success"
                          now={prod.prodQte}
                          size={1}
                        />
                      </div>
                      <div>
                        <p className="textConditions">
                          {/* + livraison à partir de 300 FCFA (livraison gratuite
                          si supérieur à 5,000 FCFA) vers Koumassi Divo -
                          Remblais - SIR */}
                          {prod.prodCondi}
                        </p>
                      </div>
                      {/* <Button variant="danger">Ajouter au panier</Button>{" "}
                    <Button variant="secondary">Secondary</Button>{" "}     //onClick={() => handleDelete(row.id)} */}
                      <hr />
                      <div className="d-grid gap-2 d-flex justify-content-between">
                        <Button
                          variant="danger"
                          className="mr-2"
                          onClick={handleAddToCart}
                        >
                          <i class="fas fa-cart-plus"></i> Ajouter au panier
                        </Button>
                        {favory ? (
                          favoryBody()
                        ) : (
                          <Button
                            variant="outline-warning"
                            onClick={() => handleAddWishlist(prod.prodId)}
                          >
                            <i class="far fa-heart text-whiteX"></i>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} sm={12}>
              <Card className="p-2">
                <Card.Header>LIVRAISON & RETOURS</Card.Header>
                <Card.Body>
                  <p>
                    <small> Livraison en 24H à Abidjan.</small>
                    {/* <Link>Détails</Link> */}
                  </p>
                  <div className="d-flex justify-content-between">
                    <span>
                      <i class="fas fa-shipping-fast mr-2"></i>
                    </span>
                    <p className="shippingTitle">
                      <span className="font-weight-bold">
                        Livraison à domicile{" "}
                      </span>{" "}
                      <br /> Avec E-Triangle profitez d'une livraison plus
                      rapide
                      <span>{/* <Link> Détails</Link> */}</span>
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span>
                      <i class="fas fa-undo-alt mr-2"></i>
                    </span>
                    <p className="shippingTitle">
                      <span className="font-weight-bold">
                        Politique de retour{" "}
                      </span>{" "}
                      <br />
                      Retours gratuits sur 10 jours.
                      <span>{/* <Link> Détails</Link> */}</span>
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="home" title="Description">
                  <p>{parse(prod.prodBody)}</p>
                </Tab>
                <Tab eventKey="profile" title="Politique de retour">
                  {}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default DetailProduct;
