import React from "react";
import { Fragment } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { removeItem } from "../../store";
function RemoveItem({ show, handleClose, itemId }) {
  const dispatch = useDispatch();
  //alert(itemId);
  const handleRemoveItem = () => {
    dispatch(removeItem(itemId));
    if (handleClose) handleClose();
  };
  return (
    <Fragment>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            Retirer du panier ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">Voulez-vous vraiment retirer cet article du panier?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleRemoveItem}
            variant="danger"
            className="btn btn-block"
          >
            <i class="fas fa-trash-alt"></i> RETIRER L'ARTICLE
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default RemoveItem;
