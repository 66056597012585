import React, { Fragment, useEffect, useState } from "react";
import dateFormat, { masks } from "dateformat";
import api from "../../api";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import "primeicons/primeicons.css";
//theme
// import "primereact/resources/themes/mdc-light-deeppurple/theme.css";
//core
import "primereact/resources/primereact.min.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Tag } from "primereact/tag";
import { TriStateCheckbox } from "primereact/tristatecheckbox";

import { Link } from "react-router-dom";
function ReadOrders(props) {
  const [order, setOrder] = useState([]);
  const [statutOrder, setStatutOrder] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "customer.cust_name": {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    ttc_amount: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const fetchOrders = () => {//sessionStorage.getItem('userId')
    api.get(`Orders/orderBySeller/` + sessionStorage.getItem("SellerId")).then((res) => {
      const orders = res.data.result.orders;
      console.log(orders);
      setOrder(orders);
    });
  };
  const fetchStatutOrders = () => {
    api.get(`StatutOrders/`).then((res) => {
      const statutOrder = res.data.result.statutOrders;
      console.log('statutOrder',statutOrder);
      setStatutOrder(statutOrder);
    });
  };

  
useEffect(() => {
    fetchOrders();
    fetchStatutOrders();
    setLoading(false);
  }, []);

  //Template maker
  const orderBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Link to={"/order-view?IdOrders=" + rowData.id+"&IdCust="+rowData.customer_id}>#{rowData.id}</Link>
      </Fragment>
    );
  };
  const dateBodyTemplate = (rowData) => {
    return dateFormat(rowData.creadted, "dd/mm/yyyy");
  };
  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.statut_order.title} severity={rowData.statut_order.color} />;
};
  const statusItemTemplate = (rowData) => {
        return <Tag value={rowData.statut_order.title}  severity={rowData.statut_order.color} />;
    };
    const [statuses] = useState(['unqualified', 'qualified', 'new', 'negotiation', 'renewal']);
const statusRowFilterTemplate = (options) => {
  return (
    <Dropdown value={options.value} options={statuses} 
    onChange={(e) => options.filterApplyCallback(e.value)}
     itemTemplate={statusItemTemplate} placeholder="Select One" 
     className="p-column-filter" showClear style={{ minWidth: '12rem' }} />

  );
};
  const renderHeader = () => {
    return (
      <div className="d-flex  justify-content-between align-items-center">
        <p>MA LISTE DE COMMANDE</p>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Recherche par client, N° commande"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();

  return (
    <Fragment>
      <Container>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <DataTable
                  value={order}
                  tableStyle={{ minWidth: "50rem" }}
                  loading={loading}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} de {last} à {totalRecords}"
                  filterDisplay="row"
                  globalFilterFields={["ttc_amount", "customer.cust_name"]}
                  filters={filters}
                  header={header}
                  emptyMessage="Aucune commande trouvée."
                >
                  <Column
                    body={orderBodyTemplate}
                    sortable
                    field="id"
                    header="N°"
                  ></Column>
                  <Column
                    sortable
                    field="customer.cust_name"
                    header="CLIENT"
                  ></Column>
                  <Column
                    field="customer.cust_phone"
                    header="TELEPHONE"
                  ></Column>
                  <Column field="ttc_amount" header="TOTAL"></Column>
                  <Column field="article_amount" header="ARTICLE"></Column>
                  <Column field="shipping_amount" header="LIVRAISON"></Column>
                  <Column field="fees_amont" header="COMMISSION"></Column>
                  <Column
                    sortable
                    field="statut_order.title"
                    body={statusBodyTemplate}
                    showFilterMenu={false}
                    filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }}
                   // filter filterElement={statusRowFilterTemplate}
                    header="STATUT"
                  ></Column>
                  <Column
                    sortable
                    field="creadted"
                    body={dateBodyTemplate}
                    header="DATE"
                  ></Column>
                </DataTable>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default ReadOrders;
