import React, { useState } from "react";
import { Fragment } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../api";
import { Navigate } from "react-router";
import "./style.css";
function ForgetPassword(props) {
  const [form, setForm] = useState({
    sellerEmail: "",
  });

  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const _form = {
      username: form.sellerEmail,
    };
    try {
     // alert(form.sellerEmail);
      api.post(`Users/resetPassword`, _form).then((res) => {
        if (res.data.status === "OK") {
          //console.log("Seller Account  saved !");
          // reFresh();
          document.getElementById("resetForm").reset();
          Swal.fire({
            icon: "success",
            title: "Mot de passe reinitialisé avec succès",
            text: "Nous  avons envoyé   un mot de passe dans votre  messagerie",
          });
        }
        return <Navigate to="/login" />;
      });
    } catch (e) {
      ///console.log("Seller Account not saved !");
    }
  };
  return (
    <Fragment>
      <section className="py-5">
        <div className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="formCard">
              <div className="card w-5 cardBorder">
                <div class="text-center mb-2">
                  <img
                    src="https://e-triangle.com/Logo_e-triangle.png"
                    alt="Image"
                    height="80"
                    class="mb-3"
                  />
                  <div class="text-900 text-3xl font-medium mb-3 font-weight-bold">
                    Mot de passe oublié ?
                  </div>
                  <span class="text-600">
                    Veillez saisir votre adresse email
                  </span>
                </div>
                <form
                  id="resetForm"
                  onSubmit={handleSubmit}
                  className="w-full surface-card py-8 px-5"
                >
                  <div>
                    <span className="p-float-label  mb-3">
                      <InputText
                        className="p-inputtext"
                        value={form.sellerEmail}
                        onChange={(e) =>
                          handleChange("sellerEmail", e.target.value)
                        }
                      />
                      <label htmlFor="username">Adresse email</label>
                    </span>
                  </div>
                  <div className="mb-3">
                    <Button
                      label="Envoyer"
                      className="p-button p-component w-full btn btn-block"
                      severity="primary"
                      raised
                    />
                  </div>
                  <div className="mb-3">
                    {" "}
                    <Link to={"/login"}>Connectez-vous</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default ForgetPassword;
