import React, { Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import api from "../../api";
function ResetPassword({ show, handleClose, email }) {
  
  const handleResetPassword = () => {
    const _form = {
      username: email,
    };
    try {
      api.post(`Users/resetPassword`, _form).then((res) => {
        if (res.data.status === "OK") {
          Swal.fire({
            icon: "success",
            title: "Mot de passe reinitialisé avec succès",
            text: "Nous  avons envoyé   un mot de passe dans votre  messagerie",
          });
        }
      });
    } catch (e) {
      ///console.log("Seller Account not saved !");
    }
  };
  return (
    <Fragment>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Reinitialisation mot de passe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Voulez-vous vraiment reinitialiser votre mot de passe ?
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="danger"
            className="btn btn-block"
            onClick={handleResetPassword}
          >
            <i class="fas fa-undo-alt"></i> Oui, Reinitialiser
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default ResetPassword;
