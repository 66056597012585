import React, { Fragment, useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import api from "../../api";
function OrderMonthChart(props) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [orders, setOrders] = useState([]);
  const fetchOrders = () => {
    api.get(`Dashboard/getOrderChart/` + sessionStorage.getItem("SellerId")).then((res) => {
      const orders = res.data.result.orders;
      console.log(orders);
      setOrders(orders);
    });
  };
  useEffect(() => {
    fetchOrders();
    const chartOrders = {
      labels: orders.map((x) => x.Orders__date),
      datasets: [
        {
          label: "Montant",
          data: orders.map((x) => x.ttc_amount),
          backgroundColor: [
            "rgba(255, 159, 64, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(153, 102, 255, 0.2)",
          ],
          borderColor: [
            "rgb(255, 159, 64)",
            "rgb(75, 192, 192)",
            "rgb(54, 162, 235)",
            "rgb(153, 102, 255)",
          ],
          borderWidth: 1,
        },
      ],
    };
    const options = {
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    setChartData(chartOrders);
    setChartOptions(options);
  }, []);

  return (
    <Fragment>
      <Chart type="pie" data={chartData} options={chartOptions} />
    </Fragment>
  );
}

export default OrderMonthChart;
