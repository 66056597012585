import React, { Fragment, useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import api from "../../api";

function SalesMonthChart(props) {
  const [sales, setSales] = useState([]);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const fetchSales = () => {
    api.get(`Dashboard/getsaleChart/` + sessionStorage.getItem("SellerId")).then((res) => {
      const sales = res.data.result.sales;
      console.log(sales);
      setSales(sales);
    });
  };
  useEffect(() => {
    fetchSales();
    const chartSales = {
      labels: sales.map((x) => x.Paiements__date),
      //["Q1", "Q2", "Q3", "Q4"],
      datasets: [
        {
          label: "Vente réalisée",
          data: sales.map((x) => x.Paiements__amount),
          //[540, 325, 702, 620],
          backgroundColor: [
            "rgba(255, 159, 64, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(153, 102, 255, 0.2)",
          ],
          borderColor: [
            "rgb(255, 159, 64)",
            "rgb(75, 192, 192)",
            "rgb(54, 162, 235)",
            "rgb(153, 102, 255)",
          ],
          borderWidth: 1,
        },
      ],
    };
    const options = {
        responsive: true,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    setChartData(chartSales);
    setChartOptions(options);
  }, []);
  return (
    <Fragment>
        <p>Total Vente du mois</p>
      <Chart type="line" data={chartData} options={chartOptions} />
    </Fragment>
  );
}

export default SalesMonthChart;
