import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ResetPassword from "./ResetPassword";
import api from "../../api";
import Swal from "sweetalert2";
function UserProfil(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    addr: "",
    body: "",
  });
  const fetchSeller = () => {
    api.get(`Sellers/getSellerById/` + sessionStorage.getItem("SellerId")).then((res) => {
      setForm({
        firstName: res.data.result.sellers[0].first_name,
        lastName: res.data.result.sellers[0].last_name,
        shopName: res.data.result.sellers[0].shop_name,
        email: res.data.result.sellers[0].email,
        phoneNo: res.data.result.sellers[0].phone_no,
        addr: res.data.result.sellers[0].adresse,
        body: res.data.result.sellers[0].body,
      });
    });
  };
  useEffect(() => {
    if (7) {
      fetchSeller();
    }
  }, [7]);

  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };

  //
  const handleSave = (e) => {
    e.preventDefault();
    const _form = {
      first_name: form.firstName,
      last_name: form.lastName,
      shopName: form.shopName,
      email: form.email,
      phone_no: form.phoneNo,
      adresse: form.addr,
      body: form.body,
    };
    try {
      api.post(`Sellers/edit/` + sessionStorage.getItem("SellerId"), _form).then((res) => {
        if (res.data.status === "OK") {
          console.log("Seller Account  saved !");
            Swal.fire({
              icon: "success",
              title: "Modifié avec succès",
              text: "Vos informations ont été modifiées avec succès",
            });
        }
      });
    } catch (e) {
      console.log("Seller Account not saved !");
    }
  };
  return (
    <Fragment>
      <section className="py-2">
        <Container>
          <Row>
            <Col></Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <div class="d-flex justify-content-between">
                    <strong className="m-2">CONFIGURATION DE PROFIL</strong>
                    <Button variant="light" onClick={handleShow}>
                      <i class="fas fa-key text-danger"></i> Reinitialiser mot
                      de passe
                    </Button>{" "}
                  </div>
                </Card.Header>
                <Card.Body>
                  <div>
                    <Form onSubmit={handleSave}>
                      <Row>
                        <Col>
                          {" "}
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Nom</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              value={form.firstName}
                              onChange={(e) =>
                                handleChange("firstName", e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          {" "}
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Prénoms</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              value={form.lastName}
                              onChange={(e) =>
                                handleChange("lastName", e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {" "}
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              required
                              readOnly
                              value={form.email}
                              onChange={(e) =>
                                handleChange("email", e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          {" "}
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Téléphone</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              pattern="[0-9]{0}[0-9]{10}"
                              value={form.phoneNo}
                              onChange={(e) =>
                                handleChange("phoneNo", e.target.value)
                              }
                            />
                            <Form.Text muted>
                              <small className="text-danger">
                                Entrez un numero de téléphone à 10 chiffres.
                                Votre numero WhatsApp de préférence
                              </small>{" "}
                            </Form.Text>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Adresse</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          value={form.addr}
                          onChange={(e) => handleChange("addr", e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Note</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={form.body}
                          onChange={(e) => handleChange("body", e.target.value)}
                        />
                      </Form.Group>

                      <Button variant="success" type="submit">
                        Enregistrer
                      </Button>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <ResetPassword show={show} handleClose={handleClose} email={sessionStorage.getItem("email")}/>
      </section>
    </Fragment>
  );
}

export default UserProfil;
