import React, { Fragment, useEffect, useState } from "react";
import api from "../../api";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import "primeicons/primeicons.css";
//theme
import "primereact/resources/themes/mdc-light-deeppurple/theme.css";
//core
import "primereact/resources/primereact.min.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

function ReadTarifs(props) {
  const [tarif, setTarif] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    title: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    price: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const fetchTarifs = () => {
    api.get(`AdressCategories/`).then((res) => {
      const adressCategories = res.data.result.adressCategories;
      console.log(adressCategories);
      setTarif(adressCategories);
    });
  };
  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text />;
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  
  const renderHeader = () => {
    return (
      <div className="d-flex  justify-content-between align-items-center">
        <p>LISTE DES TARIFS</p>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Recherche par mot clé"
          />
        </span>
      </div>
    );
  };
  useEffect(() => {
    fetchTarifs();
    setLoading(false);
  }, []);
  const header = renderHeader();
  return (
    <Fragment>
      <Container>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <DataTable
                  value={tarif}
                  paginator
                  rows={10}
                  dataKey="id"
                  filters={filters}
                  filterDisplay="row"
                  loading={loading}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  tableStyle={{ minWidth: "50rem" }}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} de {last} à {totalRecords}"
                  paginatorLeft={paginatorLeft}
                  paginatorRight={paginatorRight}
                  globalFilterFields={["title", "price"]}
                  header={header}
                  emptyMessage="Aucun tarif trouvé."
                >
                  <Column sortable field="title" header="TITRE"></Column>
                  <Column
                    sortable
                    field="price"
                    text="FCFA"
                    header="PRIX"
                  ></Column>
                </DataTable>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default ReadTarifs;
