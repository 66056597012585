import { Fragment } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Layout from "./Layout";
import { useLocation } from "react-router";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ForgetPassword from "./components/auth/ForgetPassword";
function App() {
  const location = useLocation();
  if (location.pathname == "/create-account") {
    return <Register />;
  }
  else if (location.pathname == "/logout") {
    sessionStorage.clear(); // Remove all saved data from sessionStorage
    return <Login />;
  }
  else if (location.pathname == "/password-forget") {
    sessionStorage.clear(); // Remove all saved data from sessionStorage
    return <ForgetPassword />;
  }
  else if (sessionStorage.getItem("userName") == null) {
    return <Login />;
  } else {
    return (
      <Fragment>
          <div className="wrapper">
        <Navbar />
        <Sidebar />
        <Layout />
        <Footer />
        </div>
      </Fragment>
    );
  }
}
export default App;
