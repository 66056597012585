import React, { Fragment } from 'react';
import ShoppingCheckout from './ShoppingCheckout';
function Checkout(props) {
    return (
        <Fragment>
           <ShoppingCheckout/>
        </Fragment>
    );
}

export default Checkout;