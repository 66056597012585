import React, { Fragment, useEffect, useState, useRef } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SelectTarif from "../tarifs/SelectTarif";
import api from "../../api";
import { Toast } from "primereact/toast";
import { Navigate } from "react-router";
import { clearCart } from "../../store";
function ShoppingCheckout() {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const [redirect, setRedirect] = useState(false);
  //
  console.log("cartstte", cart);
  const selectCartTotalAmount = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };
  const selectCartTotalFees = () => {
    return cart.reduce((total, item) => total + item.fees * item.quantity, 0);
  };

  const selectCartTotal = () => {
    return cart.reduce(
      (total, item) =>
        total + (item.fees * item.quantity + item.price * item.quantity),
      0
    );
  };

  //
  const [tarifAmount, setTarifAmount] = useState(0);

  const handleChangeTarif = (e) => {
    api
      .get(`AdressCategories/getAdressCategories/` + e.target.value)
      .then((res) => {
        const price = res.data.result.adressCategories[0].price;
        console.log(price);
        setTarifAmount(price);
      });
  };
  //
  const [cartItems, setCartItems] = useState([cart]);
  const [form, setForm] = useState({
    customerName: "",
    phoneNumber: "",
    shippingDate: "",
    adrrId: "",
    adrBody: "",
  });
  const [orderId, setOrderId] = useState()
  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };
  const toast = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      cust_name: form.customerName,
      cust_phone: form.phoneNumber,
      adress: form.adrBody,
      shipping_date: form.shippingDate,
      shipping_amount: tarifAmount,
      article_amont: selectCartTotalAmount(),
      ttc_amount: selectCartTotal() + tarifAmount,
      fees_amont: selectCartTotalFees(),
      seller_id: sessionStorage.getItem("SellerId"),
      rowData: cart,
    };
    api.post(`Orders/add`, data).then((res) => {
      if (res.data.status === "OK") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Votre Commande a été  enregistrée !",
        });
        setOrderId(res.data.result.getordersId)
        dispatch(clearCart());
        console.log(res);
        setRedirect(true);
      } else {
        // this.ErrorSwal();
      }
    });
  };
  if (redirect) return <Navigate to={"/checkout-success?orderId=" + orderId} />;
  return (
    <Fragment>
      <section className="py-1">
        <Container>
          <Row>
            <Col>
              {" "}
              {/* <h5>Cart</h5> */}
              <Link to={"/cart"} className="btn btn-default mb-1">
                <i class="fas fa-angle-left"></i> Retourner au panier
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-2">
        <Toast ref={toast} />
        <Form onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col md={6} sm={12}>
                <Card className="p-2">
                  <Card.Header>INFORMATION DU CLIENT</Card.Header>
                  <Card.Body>
                    <Card.Title className="mb-3c">
                      {" "}
                      <strong>
                        <i class="fas fa-user-tag"></i> CONTACT CLIENT
                      </strong>{" "}
                    </Card.Title>

                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Nom & prénoms client"
                        required
                        value={form.customerName}
                        onChange={(event) =>
                          handleChange("customerName", event.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Numero de téléphone client"
                        required
                        pattern="[0-9]{0}[0-9]{10}"
                        value={form.phoneNumber}
                        onChange={(event) =>
                          handleChange("phoneNumber", event.target.value)
                        }
                      />
                      <Form.Text id="passwordHelpBlock" muted>
                        Entrez un numero de téléphone à 10 chiffres.
                      </Form.Text>
                    </Form.Group>
                  </Card.Body>
                  <Card.Body>
                    <Card.Title>
                      {" "}
                      <strong>
                        <i class="fas fa-map-marker-alt"></i> DETAILS DE
                        LIVRAISON
                      </strong>
                    </Card.Title>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="date"
                        required
                        value={form.shippingDate}
                        onChange={(event) =>
                          handleChange("shippingDate", event.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Select
                        className="form-control"
                        onChange={handleChangeTarif}
                        required
                      >
                        <SelectTarif />
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Description du lieu de livraison"
                        required
                        value={form.adrBody}
                        onChange={(event) =>
                          handleChange("adrBody", event.target.value)
                        }
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} sm={12}>
                <Card className="p-2">
                  <Card.Header>RESUME DU PANIER</Card.Header>
                  <Card.Body>
                    <ul class="list-unstyled">
                      {cart.map((items) => (
                        <li class="media mb-3">
                          <img
                            src={"http://medias.e-triangle.com/" + items.image}
                            height={52}
                            width={52}
                            class="mr-3 mb-0  border-radius-5 shadow-sm"
                          />
                          <div class="media-body">
                            <small className="text-lowercaseX">
                              {" "}
                              {items.name}
                            </small>
                            <p className="small text-secondary font-weight-bold">
                              {" "}
                              {items.quantity} x {items.price}FCFA +{" "}
                              {items.quantity} x {items.fees}FCFA de commission
                            </p>
                            <p className="font-weight-bold small summaryAmount">
                              {items.quantity * items.price +
                                items.quantity * items.fees}
                              FCFA
                            </p>
                          </div>
                        </li>
                      ))}
                      <li className="d-flex justify-content-between">
                        <p>Sous-total</p>
                        <p>
                          {selectCartTotalAmount() + selectCartTotalFees()}FCFA
                        </p>
                      </li>
                      <li className="d-flex justify-content-between">
                        <p> Frais de livraison</p>
                        <p>{tarifAmount}FCFA</p>
                      </li>
                    </ul>
                  </Card.Body>
                  <Card.Footer className="d-flex justify-content-end font-weight-bold">
                    <p className="mr-2">Total à payer</p>
                    <p>
                      {selectCartTotal() + tarifAmount}
                      {" FCFA"}
                    </p>
                  </Card.Footer>
                </Card>
                <div className="d-grid gap-2 mt-2">
                  <Button type="submit" className="btn btn-danger btn-block">
                    {" "}
                    CONFIRMER LA COMMANDE
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      </section>
    </Fragment>
  );
}

export default ShoppingCheckout;
