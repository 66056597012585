import React, { Fragment, useEffect, useState } from "react";
import dateFormat, { masks } from "dateformat";
import api from "../../api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import "primereact/resources/themes/tailwind-light/theme.css";
import { Tag } from "primereact/tag";
function OrdersMonthList(props) {
  const [order, setOrder] = useState([]);
  const fetchOrders = () => {
    api.get(`Orders/orderBySeller/` + sessionStorage.getItem("SellerId")).then((res) => {
      const orders = res.data.result.orders;
      console.log(orders);
      setOrder(orders);
    });
  };
  useEffect(() => {
    fetchOrders();
  }, []);
  
  const dateBodyTemplate = (rowData) => {
    return dateFormat(rowData.creadted, "dd/mm/yyyy");
  };
  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.statut_order.title} severity={rowData.statut_order.color} />;
};
  return (
    <Fragment>
        <p className="font-weight-bold">Synthèse Commande du mois</p>
      {" "}
      <DataTable value={order}>
        <Column field="id" header="N°"></Column>
        <Column field="ttc_amount" header="Montant"></Column>
        <Column  field="statut_order.title"   body={statusBodyTemplate} header="Statut"></Column>
        <Column field="quantity" body={dateBodyTemplate} header="Date"></Column>
      </DataTable>
    </Fragment>
  );
}

export default OrdersMonthList;
