import React, { Fragment, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import api from "../../api";
import Card from "react-bootstrap/Card";
import "./style.css";
import TruncateMarkup from "react-truncate-markup";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
function SpecialPyjamas({ categoryId }) {
  const [product, SetProduct] = useState([]);
  const fetchProduct = () => {
    api.get(`Products/specialPyjamas/` + categoryId).then((res) => {
      const products = res.data.result.products;
      // console.log('childresnnnn',products);
      SetProduct(products);
    });
  };
  useEffect(() => {
    fetchProduct();
  }, []);

  return (
    <Fragment>
      <Container>
        <Row>
          {product.map((prod) => (
            <Col md={3} xs={6}>
              <Card className="mobileCard">
                <LazyLoadImage
                  effect="blur"
                  variant="top"
                  className="imgCard img-fluid"
                  src={"http://medias.e-triangle.com/" + prod.prod_image} // use normal <img> attributes as props
                />
                <Card.Body className="cardBody">
                  <Card.Title className="producTitle">
                    <TruncateMarkup lines={1}>
                      <div>
                        <Link
                          to={"/detail-product?IdProd=" + prod.id}
                          className="prodName"
                        >
                          {" "}
                          {prod.prod_name}
                        </Link>
                        <br />
                      </div>
                    </TruncateMarkup>
                  </Card.Title>
                  <div>
                    <Badge bg="light">
                      <span className="text-danger mobileCatTitle">
                        {prod.sub_category.category.cat_title}
                      </span>
                    </Badge>
                  </div>
                  <div>
                    <strong className="mobileCatTitle">
                      {prod.prod_pu} FCFA
                    </strong>
                    <span></span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Fragment>
  );
}

export default SpecialPyjamas;
