import React, { Fragment, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import "primeicons/primeicons.css";
//theme
import "primereact/resources/themes/mdc-light-deeppurple/theme.css";
//core
import "primereact/resources/primereact.min.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import api from "../../api";
function MyWishlist(props) {
  const [wishlist, setWishlist] = useState([]);
  const fetchT = () => {
    api.get(`Wishlists/getSellerWishlists` + sessionStorage.getItem("SellerId")).then((res) => {
      const wishlists = res.data.result.wishlists;
      console.log(wishlists);
      setWishlist(wishlists);
    });
  };
  return (
    <Fragment>
      <section>
        <Container>
          <Row>
            <Col>
              <Card>
                <Card.Body></Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default MyWishlist;
