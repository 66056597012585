import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import api from "../../api";

function SelectTarif(props) {
  const [tarif, setTarif] = useState([]);
  const fetchTarifs = () => {
    api.get(`AdressCategories/`).then((res) => {
      const adressCategories = res.data.result.adressCategories;
      console.log(adressCategories);
      setTarif(adressCategories);
    });
  };
  useEffect(() => {
    fetchTarifs();
  }, []);
  return (
    <Fragment>
      <option selected disabled value="">
        Selectionnez...
      </option>
      {
      tarif.map((row,i) => (
        <option value={row.id} key={i}>{row.title}</option>
      ))}
    </Fragment>
  );
}

export default SelectTarif;
