import React, { Fragment, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import { Link } from "react-router-dom";
import "primeicons/primeicons.css";
import AddAmontFees from "./AddAmontFees";
import { updateItem } from "../../store";
import RemoveItem from "./RemoveItem";
function ShoppingCart(props) {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [itemQuantity, setItemQuantity] = useState(0);
  const [show, setShow] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [feesMode, setFeesMode] = useState(false);
  const [itemId, setItemId] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setItemId(id);
    setShow(true);
  };

  const handleDeleteMode = (id) => {
    setItemId(id);
    setDeleteMode(true);
    setFeesMode(false);
    setShow(true);
  };
  const handleFeesMode = (id) => {
    setItemId(id);
    setDeleteMode(false);
    setFeesMode(true);
    setShow(true);
  };
  const handleAddQuantity = (id) => {
    setItemQuantity(1);
    setItemId(id);
    const updatedItem = {
      id: id,
      quantity: 1,
    };
    dispatch(updateItem(updatedItem));
  };
  const handleRemoveQuantity = (id) => {
    setItemQuantity(-1);
    setItemId(id);
    const updatedItem = {
      id: id,
      quantity: -1,
    };
    dispatch(updateItem(updatedItem));
  };

  //
  const selectCartTotalAmount = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };
  const selectCartTotalFees = () => {
    return cart.reduce((total, item) => total + item.fees * item.quantity, 0);
  };

  const selectCartTotal = () => {
    return cart.reduce(
      (total, item) =>
        total + (item.fees * item.quantity + item.price * item.quantity),
      0
    );
  };

  return (
    <Fragment>
      <section className="py-1">
        <Container>
          <Row>
            <Col>
              {" "}
              {/* <h5>Cart</h5> */}
              <Link to={"/search-product"} className="btn btn-default mb-1">
                <i class="fas fa-angle-left"></i> continuer vos achats
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col md={8}>
              <Card className="p-2">
                <Card.Header>PANIER</Card.Header>
                <Card.Body>
                  <ul class="list-unstyled">
                    {cart.map((items) => (
                      <li class="media mb-2 py-2X">
                        <img
                          src={"http://medias.e-triangle.com/" + items.image}
                          height={72}
                          width={72}
                          class="mr-3  border-radius-5 shadow-sm"
                        />

                        <div class="media-body">
                          <p class="mt-0 mb-1">
                            <Link
                              to={"/detail-product?IdProd=" + items.id}
                              className="prodNameLink"
                            >
                              <small> {items.name}</small>
                            </Link>
                          </p>
                          <div className="d-flex justify-content-between">
                            <div className="priceCard">
                              <strong>
                                {items.price}
                                {" FCFA"}
                              </strong>{" "}
                              <small>
                                {" "}
                                <i>
                                  + {items.fees}
                                  {" FCFA"} de commision
                                </i>
                              </small>
                            </div>
                            <div className="quantityCard ">
                              <button
                                onClick={() => handleRemoveQuantity(items.id)}
                                type="button"
                                class="btn btn-danger elevation-2 btn-sm mr-3"
                              >
                                <i class="fas fa-minus"></i>
                              </button>
                              {items.quantity}
                              <button
                                onClick={() => handleAddQuantity(items.id)}
                                type="button"
                                class="btn btn-danger elevation-2 ml-3 btn-sm"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div>
                            <div className="deleteImtem mb-2">
                              <button
                                type="button"
                                class="btn btn-light btn-sm mr-5 text-danger"
                                onClick={() => handleDeleteMode(items.id)}
                              >
                                <i class="far fa-trash-alt text-danger"></i>{" "}
                                Supprimer
                              </button>
                              <button
                                type="button"
                                onClick={() => handleFeesMode(items.id)}
                                class="btn btn-light btn-sm text-success"
                              >
                                <i class="fas fa-coins"></i> Ajouter ma
                                commission
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
            <Card className="p-2">
                <Card.Header>RESUME DU PANIER</Card.Header>
                <Card.Body>
                  <ul class="list-group list-group-flush p-0">
                    <li class="list-group-item d-flex justify-content-between ">
                      <span>Sous-total</span>
                      <span>
                        {selectCartTotalAmount()} {" FCFA"}
                      </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                      <span>Total Commission</span>
                      <span>
                        {selectCartTotalFees()}
                        {" FCFA"}
                      </span>
                    </li>
                  </ul>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between">
                  <p> Montant Total</p>
                  <strong>
                    {selectCartTotal()}
                    {" FCFA"}
                  </strong>
                </Card.Footer>
              </Card>
              <div className="d-grid gap-2 mt-2">
                <Link to={"/checkout"} className="btn btn-danger btn-block">
                  CONTINUER LA COMMANDE
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/*  */}
      {deleteMode && (
        <RemoveItem itemId={itemId} show={show} handleClose={handleClose} />
      )}
      {feesMode && (
        <AddAmontFees itemId={itemId} show={show} handleClose={handleClose} />
      )}
    </Fragment>
  );
}

export default ShoppingCart;
