import React, { Fragment } from 'react';
import ShoppingCart from './ShoppingCart';

function Cart(props) {
    return (
        <Fragment>
          <ShoppingCart/>
        </Fragment>
    );
}

export default Cart;