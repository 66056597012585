import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import api from "../../api";
import SummaryOrder from "./SummaryOrder";
import CustomerOrder from "./CustomerOrder";
import { Container, Row, Col, Card } from "react-bootstrap";
function SellerOrder(props) {
  const [ligneOrders, setLigneOrders] = useState([]);
  const [custOrder, setCustOrder] = useState({
    custName: "",
    phoneNo: "",
    addr: "",
    shippingDate: "",
    orderShipping: "",
    //
    articleAmount: "",
    shippingAmont: "",
    orderStatus: "",
    orderStatut: "",
    feesAmount: "",
  });
  const myLink = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("IdOrders");
  };
  const fetchOrders = () => {
    api.get(`LigneOrders/getOrderLineById/` + myLink()).then((res) => {
      const ligneOrders = res.data.result.ligneOrders;
      console.log(ligneOrders);
      setLigneOrders(ligneOrders);
      setCustOrder({
        custName: res.data.result.ligneOrders[0].order.customer.cust_name,
        phoneNo: res.data.result.ligneOrders[0].order.customer.cust_phone,
        addr: res.data.result.ligneOrders[0].order.customer.cust_name,
        shippingDate:
          res.data.result.ligneOrders[0].order.shippings[0].shipping_date,
        orderShipping: res.data.result.ligneOrders[0].order.shippings[0].adress,
        articleAmount: res.data.result.ligneOrders[0].order.article_amount,
        feesAmount: res.data.result.ligneOrders[0].order.fees_amont,
        shippingAmont: res.data.result.ligneOrders[0].order.shipping_amount,
        orderStatus: res.data.result.ligneOrders[0].order.statut_order.title,
      });
    });
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`https://medias.e-triangle.com/${rowData.product.prod_image}`}
        alt={rowData.image}
        className="shadow-2 border-round"
        style={{ width: "64px" }}
      />
    );
  };

  return (
    <Fragment>
      <DataTable value={ligneOrders} tableStyle={{ minWidth: "50rem" }}>
        <Column
          field="product.prod_image"
          body={imageBodyTemplate}
          header=""
        ></Column>
        <Column field="product.prod_name" header="Produit"></Column>
        <Column field="qauntity" header="Quantité"></Column>
        <Column field="pu" header="Prix"></Column>
        <Column field="fees_amount" header="Commission"></Column>
        <Column field="order.ttc_amount" header="Montant"></Column>
      </DataTable>
      <hr/>
      <Row>
        <Col md={6}>
          <Card className="p-3">
            <Card.Header>DETAILS CLIENT</Card.Header>
            <Card.Body>
              <CustomerOrder
                cust={custOrder.custName}
                tel={custOrder.phoneNo}
                adr={custOrder.orderShipping}
                shippindate={custOrder.shippingDate}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="p-3">
            <Card.Header>RESUME DU PANIER</Card.Header>
            <Card.Body>
              <SummaryOrder
                st={custOrder.articleAmount}
                fees={custOrder.feesAmount}
                shipAmont={custOrder.shippingAmont}
              />
            </Card.Body>
            <Card.Footer className="d-flex justify-content-between font-weight-bold">
              <p>TOTAL</p>
              <p>{custOrder.articleAmount + custOrder.shippingAmont}</p>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default SellerOrder;
