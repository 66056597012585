import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./style.css";
import Card from "react-bootstrap/Card";
import OrdersMonthList from "./OrdersMonthList";
import OrderMonthChart from "./OrderMonthChart";
import SalesMonthChart from "./SalesMonthChart";
import SellerBox from "./SellerBox";
import api from "../../api";
function Dasboard(props) {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
  });
  const [sellerId, setSellerId] = useState(sessionStorage.getItem("SellerId"));

  const fetchSeller = () => {
    api
      .get(`Sellers/getSellerById/` + sessionStorage.getItem("SellerId"))
      .then((res) => {
        setForm({
          firstName: res.data.result.sellers[0].first_name,
          lastName: res.data.result.sellers[0].last_name,
        });
      });
  };
  //
  useEffect(() => {
    if (sellerId) {
     // alert("OK");
      fetchSeller();
    }
  }, [sellerId]);
  return (
    <Fragment>
      <section className="bgSection py-2 mb-3 elevation-1">
        <Container>
          <Row>
            <Col>
              <h1>
                Bonjour,{form.firstName } {form.lastName}
              </h1>
              <p>Synthèse de votre performance annuelle</p>
            </Col>
            {/* <Col className=" tbImg">
            </Col> */}
          </Row>
        </Container>
      </section>
      <section>
        <SellerBox />
      </section>
      <section>
        <Container>
          <Card>
            {" "}
            <Row>
              <Col>
                <Card.Body>
                  <OrdersMonthList />
                </Card.Body>
              </Col>
              <Col>
                <Card.Body>
                  <OrderMonthChart />
                </Card.Body>
              </Col>{" "}
            </Row>
          </Card>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <SalesMonthChart />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default Dasboard;
