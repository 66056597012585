import React, { Fragment, useState ,useRef} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Toast } from 'primereact/toast';
import { useDispatch } from 'react-redux';
import { addFees } from "../../store";

function AddAmontFees({ show, handleClose,itemId }) {
  const toast = useRef(null);
  const [amount, setAmount] = useState("");
  const [form, setForm] = useState({
    itemId:itemId,
    amount:"",
  });
  const dispatch = useDispatch();
  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };

  const addAmountFees=()=>{
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Commission ajoutée  !' });
    const addAmount = {
        id: itemId,
        fees: form.amount,
      };
      dispatch(addFees(addAmount));
      setForm({
        itemId:"",
        amount:"",
      })
      if (handleClose) handleClose();
    };
  
  return (
    <Fragment>
      <Modal show={show} onHide={handleClose}>

        <Modal.Header>
          <Modal.Title className="text-danger"><i class="fas fa-coins"></i>{" "}COMMISSION</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className=" border-0 flex justify-content-center">
              <span className="p-float-label">
              <Form.Control type="number" 
                 required
                 value={form.amount}
                 onChange={(event) =>
                   handleChange("amount", event.target.value)
                 }
              />
                <label htmlFor="number-input">Montant de la commission</label>
              </span>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button variant="success" onClick={addAmountFees}>
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
      <Toast ref={toast} />
    </Fragment>
  );
}

export default AddAmontFees;
