import React, { Fragment } from 'react';
import UserProfil from './UserProfil';

function User(props) {
    return (
        <Fragment>
            <UserProfil/>
        </Fragment>
    );
}

export default User;