import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import Dasboard from "./components/dashboard";
import Tarif from "./components/tarifs";
import Payement from "./components/payement";
import Orders from "./components/orders";
import ViewOrder from "./components/orders/ViewOrder";
import Product from "./components/product";
import DetailProduct from "./components/product/DetailProduct";
import Cart from "./components/cart";
import Checkout from "./components/checkout";
import User from "./components/user";
import ForgetPassword from "./components/auth/ForgetPassword";
import SuccessCheckout from "./components/checkout/SuccessCheckout";
import Wishlist from "./components/wishlist";
function Layout(props) {
  return (
    <Fragment>
      <div className="content-wrapper">
        <section className="content">
          <Routes>
            <Route>
              <Route index element={<Dasboard />} />
              <Route path="tarif-livraison" element={<Tarif />} />
              <Route path="payement" element={<Payement />} />
              <Route path="orders" element={<Orders />} />
              <Route path="order-view" element={<ViewOrder />} />
              <Route path="search-product" element={<Product />} />
              <Route path="detail-product" element={<DetailProduct />} />
              <Route path="cart" element={<Cart />} />
              <Route path="checkout" element={<Checkout />} />
              <Route path="user-profil" element={<User />} />
              <Route path="checkout-success" element={<SuccessCheckout />} />
              <Route path="wishlist" element={<Wishlist />} />
              {/* <Route path="password-forget" element={<ForgetPassword />} /> */}
            </Route>
          </Routes>
        </section>
      </div>
    </Fragment>
  );
}

export default Layout;
