import React, { useState } from "react";
import { Fragment } from "react";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import "./style.css";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../api";
import { Navigate } from "react-router";

var Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3500,
});

function Login(props) {
  const [redirect, setRedirect] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };
  const ErrorSwal = () => {
    Toast.fire({
      icon: "error",
      title: "Mot de passe ou identifiant inconnu",
    });
  };
  

  const handleSave = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("username", form.email);
    data.append("password", form.password);
    setIsloading(true);
    api.post(`Users/login`, data).then((res) => {
      if (res.data.result.feedback === "success") {
        //sessionStorage.setItem("accessToken", res.data.result.token);
        sessionStorage.setItem("userId", res.data.result.payload.id);
        sessionStorage.setItem("email", res.data.result.payload.email);
        sessionStorage.setItem("userName", res.data.result.payload.username);
        sessionStorage.setItem("SellerId", res.data.result.payload.SellerId);
        setRedirect(true);
      } else if (res.data.result.feedback === "failed") {
        setIsloading(false);
        ErrorSwal();
      }
    });
  };
  if (redirect) return <Navigate to="/" />;
  const spiner= '<div class="spinner-grow spinner-grow-sm" role="status"></div>'

  return (
    <Fragment>
      <section className="py-5">
        <div className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="formCard">
              <div className="card w-5 cardBorder">
                <div class="text-center mb-5">
                  <img
                    src="https://e-triangle.com/Logo_e-triangle.png"
                    alt="Image"
                    height="80"
                    class="mb-3"
                  />
                  <div class="text-900 text-3xl font-medium mb-3">
                    Bienvenue !
                  </div>
                  <span class="text-600 font-bold">
                    Connectez-vous pour continuer
                  </span>
                </div>
                <form onSubmit={handleSave} className="w-full surface-card py-8 px-5">
                  <div>
                    <span className="p-float-label  mb-3">
                      <InputText
                        className="p-inputtext"
                        onChange={(event) =>
                          handleChange("email", event.target.value)
                        }
                      />
                      <label htmlFor="username">Email</label>
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="p-float-label">
                      <Password
                        className="p-inputPassword"
                        toggleMask
                        inputId="password"
                        onChange={(event) =>
                          handleChange("password", event.target.value)
                        }
                      />
                      <label htmlFor="password">Mot de passe</label>
                    </span>
                  </div>
               
                  <div className="mb-3">
                    {" "}
                    <Link to={'password-forget'}>Mot de passe oublié?</Link>
                  </div>
                  <div className="mb-3">
                    <Button
                      label="Se connecter"
                      className="p-button p-component w-full"
                      severity="primary"
                      raised
                    />
                  </div>
                  <div>
                  <p> <span className="small">Vous n'avez pas de compte ? <Link to={'/create-account'}>Créer un compte</Link></span></p>
                </div>
                </form>
              
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Login;
