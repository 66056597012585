import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Navbar() {
  const cart = useSelector((state) => state.cart);
  const selectCartTotalQuantity = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };
  return (
    <Fragment>
      <Container>
        <Row>
          <Col>
            {" "}
            {/* <nav className="main-header navbar navbar-expand navbar-white navbar-light border-0">
              */} {/* <!-- Left navbar links -->
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-widget="pushmenu"
                    href="#"
                    role="button"
                  >
                    <i className="fas fa-bars"></i>
                  </a>
                </li>
              </ul> */}
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
      {/* <li class="nav-item d-none d-sm-inline-block">
        <a href="https://e-triangle.com/" class="nav-link">e-triangle.com</a>
      </li> */}
      {/* <li class="nav-item d-none d-sm-inline-block">
        <a href="#" class="nav-link">Contact</a>
      </li> */}
    </ul>
              <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                  <Link className="nav-link position-relative" to={"/cart"}>
                    <span>Panier</span>
                    <i class="fas fa-shopping-cart"></i>
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      {selectCartTotalQuantity()}
                    </span>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <div className="dropdown-divider"></div>

                    <div className="dropdown-divider"></div>

                    <div className="dropdown-divider"></div>

                    <div className="dropdown-divider"></div>
                  </div>
                </li>
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default Navbar;
