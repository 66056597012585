import React, { Fragment } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import ReadPayement from "./ReadPayement";
function Payement(props) {
  return (
    <Fragment>
      <section className="py-3">
        <Container>
          <Row>
            <Col>
              <h5 className="font-weight-bold">Mes payements</h5>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <ReadPayement />
      </section>
    </Fragment>
  );
}

export default Payement;
