import React, { Fragment, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import dateFormat, { masks } from "dateformat";
function CustomerOrder({cust,tel,adr,shippindate}) {

    return (
        <Fragment>
        <ListGroup variant="flush">
          <ListGroup.Item><span className="font-weight-bold"> Client:</span>     {cust}</ListGroup.Item>
          <ListGroup.Item><span className="font-weight-bold"> Téléphone:</span>   {tel}</ListGroup.Item>
          <ListGroup.Item><span className="font-weight-bold"> Adresse livraison:</span>  {adr}</ListGroup.Item>
          <ListGroup.Item><span className="font-weight-bold"> Date de livraison:</span>  { dateFormat(shippindate, "dd/mm/yyyy")}</ListGroup.Item>
          <ListGroup.Item><span className="font-weight-bold"> Méthode de paiement:</span>  Cash à la livraison</ListGroup.Item>
        </ListGroup>
      </Fragment>
    );
}

export default CustomerOrder;