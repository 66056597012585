import React, { useState } from "react";
import { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Card } from "primereact/card";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import "./style.css";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import api from "../../api";
import Swal from "sweetalert2";
function Register(props) {
  const [form, setForm] = useState({
    sellerEmail: "",
    sellerFirstName: "",
    sellerLastName: "",
    //sellerPassword: "",
  });

  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const _form = {
      first_name: form.sellerFirstName,
      last_name: form.sellerLastName,
      email: form.sellerEmail,
     // password: form.sellerPassword,
    };
    try {
      api.post(`Sellers/AddSeller`, _form).then((res) => {
        if (res.data.status === "OK") {
          console.log("Seller Account  saved !");
          // reFresh();
          document.getElementById("resetForm").reset();
          Swal.fire({
            icon: "success",
            title: "Enregistré avec succès",
            html: `
            <b>Bienvenue sur E-triangle !</b><br/>
            <p>Merci d'avoir rejoins la grande famille E-triangle !! 
            Votre aventure commence dès maintenant. Contactez nous via WhatsApp à travers ce lien :
            <a href=" https://wa.me/message/DIAJQFX42FYWA1"> WhatsApp</a>
            et bénéficiez d'une assistante rapide sur l'utilisation de la plateforme et les méthodes de vente .</p>
           <p> E-TRIANGLE, le commerce vous ouvre ses portes.</p>
          `,
          });
        }
      });
    } catch (e) {
      console.log("Seller Account not saved !");
    }
  };

  return (
    <Fragment>
      <section className="py-2">
        <div className="surface-ground d-flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
          <div className="flex flex-column align-items-center justify-content-center">
            <div className="formCard">
              <div className="card w-5 cardBorder">
                <div class="text-center mb-5">
                  <img
                    src="https://e-triangle.com/Logo_e-triangle.png"
                    alt="Image"
                    height="80"
                    class="mb-3"
                  />
                  <div class="text-900 text-3xl font-medium mb-3">
                    Bienvenue !
                  </div>
                  <span class="text-600 font-bold">
                    Inscrivez-vous pour continuer
                  </span>
                </div>
                <form
                  onSubmit={handleSubmit}
                  id="resetForm"
                  className="registerForm w-full surface-card py-8 px-5"
                >
                  <div>
                    <span className="p-float-label  mb-3">
                      <InputText
                        className="p-inputtext"
                        value={form.sellerFirstName}
                        onChange={(e) =>
                          handleChange("sellerFirstName", e.target.value)
                        }
                      />
                      <label htmlFor="username">Nom</label>
                    </span>
                  </div>
                  <div>
                  
                    <span className="p-float-label  mb-3">
                      <InputText
                        className="p-inputtext"
                      
                        value={form.sellerLastName}
                        onChange={(e) =>
                          handleChange("sellerLastName", e.target.value)
                        }
                      />
                      <label htmlFor="username">Prénoms</label>
                    </span>
                  </div>

                  <div>
                    <span className="p-float-label  mb-3">
                      <InputText
                        className="p-inputtext"
                      
                        value={form.sellerEmail}
                        onChange={(e) => handleChange('sellerEmail',e.target.value)}
                      />
                      <label htmlFor="username">Adresse Email</label>
                    </span>
                  </div>
                  {/* <div className="mb-3">
                    <span className="p-float-label">
                      <Password
                        className="p-inputPassword"
                        toggleMask
                        inputId="password"
                        value={form.sellerPassword}
                        onChange={(e) => handleChange('sellerPassword',e.target.value)}
                      />
                      <label htmlFor="password">Mot de passe</label>
                    </span>
                  </div> */}
                  <div className="mb-3">
                    <Button
                      type="submit"
                      label="S'inscrire"
                      className="p-button p-component w-full"
                      severity="primary"
                      raised
                    />
                  </div>
                  <div>
                    <p>
                      {" "}
                      <span className="small">
                        Vous avez un compte ?{" "}
                        <Link to={"/login"}>Connectez-vous</Link>
                      </span>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Register;
