import React, { Fragment, useEffect, useState } from "react";
import dateFormat, { masks } from "dateformat";
import api from "../../api";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import "primeicons/primeicons.css";
//theme
// import "primereact/resources/themes/mdc-light-deeppurple/theme.css";
import SellerOrder from "./SellerOrder";

function ViewOrder(props) {
  const myLink = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("IdOrders");
  };
  return (
    <Fragment>
    <section className="py-3">
        <Container>
          <Row>
            <Col>
              {" "}
              <h5 className="font-weight-bold">Commande N° #{myLink()}</h5>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col >
              <Card className="p-3">
              <Card.Header>PANIER</Card.Header>
                <Card.Body>
                  <SellerOrder/>
                  
                </Card.Body>
              </Card>
              <hr/>
            </Col>

          </Row>
  
        </Container>
      </section>
    </Fragment>
  );
}

export default ViewOrder;
