import React, { Fragment, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import api from "../../api";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Tag } from "primereact/tag";
function ReadPayement(props) {
  const [pay, setPay] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const fetchPayement = () => {
    api.get(`Paiements/`).then((res) => {
      const paiements = res.data.result.paiements;
      console.log(paiements);
      setPay(paiements);
    });
  };
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    order_id: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    amount: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  useEffect(() => {
    fetchPayement();
    // setLoading(false);
  }, []);
  const renderHeader = () => {
    return (
      <div className="d-flex  justify-content-between align-items-center">
        <p>MA LISTE DE PAYEMENT</p>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Recherche par mot clé"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();
  //Template maker
  const orderBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Link to={"/order-view?IdOrders=" + rowData.id}>#{rowData.id}</Link>
      </Fragment>
    );
  };
  //
  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.statut_payment.title} severity={rowData.statut_payment.color} />;
};
  return (
    <Fragment>
      <Container>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <DataTable
                  paginator
                  rows={5}
                  value={pay}
                  tableStyle={{ minWidth: "50rem" }}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} de {last} à {totalRecords}"
                  filterDisplay="row"
                  globalFilterFields={["order_id", "amount"]}
                  filters={filters}
                  header={header}
                  emptyMessage="Aucune commande trouvée."
                >
                  <Column headerStyle={{ width: '5rem', textAlign: 'center' }}  
                  body={orderBodyTemplate} sortable field="order_id" header="CMDE"></Column>
                  <Column sortable field="amount" header="MONTANT"></Column>
                  <Column
                    sortable
                    field="latfees_amount"
                    header="COMMISSION"
                  ></Column>
                  <Column
                    sortable
                    body={statusBodyTemplate}
                    field="statut_payment.title"
                    header="SATUT"
                  ></Column>
                </DataTable>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default ReadPayement;
