import React, { Fragment } from "react";

import SearchProduct from "./SearchProduct";
function Product(props) {
  return (
    <Fragment>
   <SearchProduct/>
    </Fragment>
  );
}

export default Product;
