import React from "react";
import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReadOrders from "./ReadOrders";
function Orders(props) {
  return (
    <Fragment>
      <section className="py-3">
        <Container>
          <Row>
            <Col>
              {" "}
              <h5 className="font-weight-bold">Mes commandes</h5>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <ReadOrders />
      </section>
    </Fragment>
  );
}

export default Orders;
