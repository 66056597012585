import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
function SuccessCheckout(props) {
  const getOrderId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("orderId");
  };
  return (
    <Fragment>
            <section className="py-1">
        <Container>
          <Row>
            <Col>
              {" "}
              {/* <h5>Cart</h5> */}
            
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <Card>
                <Card.Body className="text-center">
                  <div>
                    <h1 className="font-weight-bold"><i class="fas fa-check-circle text-success"></i> Felicitations !</h1>
                  </div>
                  <div>
                    <p>Votre commande N° {getOrderId()} a été enregistrée avec succès</p>
                  </div>
                  <p>  <Link to={"/search-product"} className="btn btn-danger">Continuer vos achats</Link></p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default SuccessCheckout;
