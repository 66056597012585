import React, { Fragment } from "react";
import ListGroup from "react-bootstrap/ListGroup";
function SummaryOrder({ st, fees, shipAmont }) {
  return (
    <Fragment>
      <ListGroup variant="flush">
        <ListGroup.Item className="d-flex justify-content-between">
          <p>Sous Total :</p>
          <p>{st}</p>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between">
          <p> Total Commission</p>
          <p>{fees}</p>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between">
          <p>Livraison</p>
          <p>{shipAmont}</p>
        </ListGroup.Item>
      </ListGroup>
    </Fragment>
  );
}

export default SummaryOrder;
