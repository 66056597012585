// store.js
import { configureStore, createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: [],
  reducers: {
    addItem: (state, action) => {
      const { id, quantity } = action.payload;
      const item = state.find((item) => item.id === id);
      if (item) {
        item.quantity += quantity;
      } else {
        state.push(action.payload);
      }
    },
    addFees: (state, action) => {
      const { id, fees } = action.payload;
      const item = state.find((item) => item.id === id);
      if (item) {
        item.fees = fees;
      }
    },

    updateItem: (state, action) => {
      const { id, quantity } = action.payload;
      const item = state.find((item) => item.id === id);
      if (item) {
        if (quantity === -1 && item.quantity === 1) {
          item.quantity = 1;
        } else {
          item.quantity += quantity;
        }
      }
    },
    
    removeItem: (state, action) => {
      const itemId = action.payload;
      return state.filter((item) => item.id !== itemId);
    },
    clearCart: (state) => {
      state.splice(0);
    },
  },
});

export const { addItem, addFees, updateItem, removeItem, clearCart } =
  cartSlice.actions;

export const store = configureStore({
  reducer: {
    cart: cartSlice.reducer,
  },
});
