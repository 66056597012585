import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Sidebar(props) {
  return (
    <Fragment>
      <aside className="main-sidebar sidebar-light-primary elevation-0">
        <a href="#" className="brand-link"> <img src="https://dropshipping.e-triangle.com/img/logo_etriangle.jpg" width={32} alt="" srcset="" />
          <span className="brand-text font-weight-light font-weight-bold text-center text-danger">
           e-triangle<br/> Dropshipping
          </span>
        </a>

        <div className="sidebar">
        <div class="user-panel mt-1 pb-1 mb-0 d-flex">
        <div class="image">
          <img src="img/avatar.jpg" class="img-circle elevation-2 bg-success" alt="User Image" />
        </div>
        <div class="info">
          <Link to={"/user-profil"} class="d-block">{sessionStorage.getItem("email")} <i class="fas fa-circle text-success"></i></Link>
          <Link to={"/logout"} className="text-danger"><i class="fas fa-sign-out-alt "></i></Link><br/>
          {/* <Link>Deconnexion </Link> */}
        </div>
      </div>
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  <i className="nav-icon fas fa-house-user"></i>
                  <p>Accueil</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/search-product" className="nav-link">
                  <i class="nav-icon  fas fa-search"></i>
                  <p>Recherche produits</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/orders" className="nav-link">
                  <i class="nav-icon  fas fa-file-invoice"></i>
                  <p>Mes commandes</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/payement" className="nav-link">
                  <i class="nav-icon  fas fa-funnel-dollar"></i>
                  <p>Mes Paiements</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/wishlist" className="nav-link">
                  <i class="nav-icon  fas fa-heart"></i>
                  <p>Mes Favories</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/tarif-livraison" className="nav-link">
                  <i class="nav-icon  fas fa-dollar-sign"></i>
                  <p>Tarifs  livraison </p>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </Fragment>
  );
}

export default Sidebar;
