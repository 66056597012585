import React, { Fragment, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import api from "../../api";
import Card from "react-bootstrap/Card";
import "./style.css";
import TruncateMarkup from "react-truncate-markup";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import CategoryProduct from "./CategoryProduct";
import SpecialPyjamas from "./SpecialPyjamas";
function SearchProduct(props) {
  const [product, SetProduct] = useState([]);
  const fetchProduct = () => {
    api.get(`Products/`).then((res) => {
      const products = res.data.result.products;
      console.log(products);
      SetProduct(products);
    });
  };
  useEffect(() => {
    fetchProduct();
  }, []);

  return (
    <Fragment>
      <section className="py-X">
        <Container>
          <Row>
            <Col></Col>
          </Row>
        </Container>
      </section>
      <section>
        <div class="card">
          <div class="card-header">
            <ul
              class="nav nav-tabs nav-tabs-neutral justify-content-center"
              role="tablist"
              data-background-color="orange"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-toggle="tab"
                  href="#all"
                  role="tab"
                >
                  <i class="fas fa-border-all"></i> Tout
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#children"
                  role="tab"
                >
                  <i class="fas fa-baby"></i> Enfant
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#pyjamas"
                  role="tab"
                >
                  <i class="fas fa-child"></i> Special Pyjamas
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#woman" role="tab">
                  <i class="fas fa-female"></i> Femme
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#home" role="tab">
                  <i class="fas fa-hotel"></i> Maison
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#gadgets"
                  role="tab"
                >
                  <i class="fas fa-swatchbook"></i> Gadgets
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body">
            {/* <!-- Tab panes --> */}
            <div class="tab-content text-centerx">
              <div class="tab-pane active" id="all" role="tabpanel">
                {/* <p>all I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at. So when you get something that has the name Kanye West on it, it’s supposed to be pushing the furthest possibilities. I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus.</p> */}
                <Container>
                  <Row>
                    {product.map((prod) => (
                      <Col md={3} xs={6}>
                        <Card className="mobileCard">
                          <LazyLoadImage
                            effect="blur"
                            variant="top"
                            className="imgCard img-fluid"
                            src={
                              "http://medias.e-triangle.com/" + prod.prod_image
                            } // use normal <img> attributes as props
                          />
                          <Card.Body className="cardBody">
                            <Card.Title className="producTitle">
                              <TruncateMarkup lines={1}>
                                <div>
                                  <Link
                                    to={"/detail-product?IdProd=" + prod.id}
                                    className="prodName"
                                  >
                                    {" "}
                                    {prod.prod_name}
                                  </Link>
                                  <br />
                                </div>
                              </TruncateMarkup>
                            </Card.Title>
                            <div>
                              <Badge bg="light">
                                <span className="text-danger mobileCatTitle">
                                  {
                                    prod._matchingData.SubCategories
                                      .sub_cat_title
                                  }
                                </span>
                              </Badge>
                            </div>
                            <div>
                              <strong className="mobileCatTitle">
                                {prod.prod_pu} FCFA
                              </strong>
                              <span></span>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </div>
              <div class="tab-pane" id="children" role="tabpanel">
                <CategoryProduct categoryId={1} />
              </div>
              <div class="tab-pane" id="pyjamas" role="tabpanel">
                <SpecialPyjamas categoryId={6}/>
              </div>
              <div class="tab-pane" id="woman" role="tabpanel">
                <CategoryProduct categoryId={2} />
              </div>
              <div class="tab-pane" id="home" role="tabpanel">
                <CategoryProduct categoryId={3} />
              </div>
              <div class="tab-pane" id="gadgets" role="tabpanel">
                <CategoryProduct categoryId={4} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default SearchProduct;
